import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { lightNovelList, getLightNovelById } from "utils/endpoint";
import Header from "components/Headers/Header";
import { updateLightNovelStatus } from "utils/endpoint";
import { toast } from "react-toastify";
import {
  get_status_list,
  get_publisher_list,
  get_type_list,
  get_User_list,
  get_PrePublication_list
} from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import ImageModal from "../../../utils/ImageModal";
import { colors } from "utils/enums";
import {
  getAuthorSummaryById,
  getIllustratorSummaryById,
  getUniverseSummaryById,
} from "utils/endpoint";
import { Pagination as Paginationes, Switch } from "antd";
import UniverseModal from "utils/universeModal";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { isBookMark } from "utils/enums";
import { createLightNovelBookMark } from "utils/endpoint";
import { deleteLightNovelBookMark } from "utils/endpoint";
import { lightNovelUpdateFlagStatus } from "utils/endpoint";
import { randomIndex } from "utils/Commen";
import RatingUserListModal from "utils/ratingUserListModal";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export const handleLightNovelBookMark = async ({isChecked,lightNovelId}) => {
  try {
    if(isChecked){
      const response =  await createLightNovelBookMark({
        light_novel_id:lightNovelId
      })

      if (response?.isSuccess) {
        toast.success("Add Bookmark successfully");
      } else {
        toast.error(response?.message);
      }
    }else {
      const response =  await deleteLightNovelBookMark(lightNovelId)
      if (response?.isSuccess) {
        toast.success("Removed Bookmark successfully");
      } else {
        toast.error(response?.message);
      }
    }

  } catch (error) {
    toast.error(error?.message);
  }
}

export const handleFilterOnKeyEnter = async(event,searchData,filterValue) =>{
  if (event.key === "Enter") {
    event.preventDefault();
    if(filterValue || filterValue?.length > 0 || filterValue === 'none' || filterValue === false) searchData();
  }
}

function LightNovelList(args) {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const enTitle = params.enTitle;
  const jpRaw = params.jpRaw;
  const jpTitle = params.jpTitle;
  const prePublicationIdsArray = params.prePublicationIdsArray;
  const userIdsArray = params.userIdsArray;
  const statusIdsArray = params.statusIdsArray;
  const publisherIdsArray = params.publisherIdsArray;
  const typeIdsArray = params.typeIdsArray;
  const is_bookmark = params.is_bookmark;
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [show, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lightNovelLists, setLightNovelLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100);
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  // const [universeList, setUniverseList] = useState([]);
  const [prePublicationList, setPrePublicationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterENTitle, setFilterENTitle] = useState(enTitle || "");
  const [filterJPTitle, setFilterJPTitle] = useState(jpTitle || "");
  const [filterJPRaw, setFilterJPRaw] = useState(jpRaw || "");
  // const [filterUniverse, setFilterUniverse] = useState("");
  const [filterPrePublication, setFilterPrePublication] = useState(prePublicationIdsArray || "");
  const [filterUser, setFilterUser] = useState(userIdsArray || "");
  const [filterStatus, setFilterStatus] = useState(statusIdsArray || "");
  const [filterPublisher, setFilterPublisher] = useState(publisherIdsArray || "");
  const [filterType, setFilterType] = useState(typeIdsArray || "");
  const [filterIsBookMark, setFilterIsBookMark] = useState(is_bookmark || "");
  // const [filterIsActive, setFilterIsActive] = useState("");
  // const [filterOnGoing, setFilterOnGoing] = useState("");
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  // const [universeSelected, setUniverseSelected] = useState(null);
  const [prePublicationSelected, setPrePublicationSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [publisherSelected, setPublisherSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [lightNovelFilter,setLightNovelFilter] = useState('');
  const [lightNovelId,setLightNovelId] = useState('');
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [ratingInfo, setRatingInfo] = useState([]);
  
  const selectInputRef = useRef(null);
  // const selectInputRefs = useRef(null);
  const imgRef = useRef(null);
  const [image, setImage] = useState("");

  const toggle = () => setIsOpen(!isOpen);
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: mySortKey,
      sort_by: mySortBy,
    };
    // if (new Date(filterFromDate) <= new Date(filterToDate)) {
    //   filter.from_date = filterFromDate;
    // }
    // if (new Date(filterToDate) >= new Date(filterFromDate)) {
    //   filter.to_date = filterToDate;
    // }
    // if (filterENTitle) {
    //   filter.en_title = filterENTitle;
    // }
    // if (filterUniverse) {
    //   filter.universe_id = filterUniverse;
    // }
    // if (filterUser) {
    //   filter.user_id = filterUser;
    // }
    // if (filterStatus) {
    //   filter.status_id = filterStatus;
    // }
    // if (filterPublisher) {
    //   filter.publisher_id = filterPublisher;
    // }
    // if (filterType) {
    //   filter.type_id = filterType;
    // }

    // if (filterOnGoing === true) {
    //   filter.ongoing = "true";
    // } else if (filterOnGoing === false) {
    //   filter.ongoing = "false";
    // }
    // if (filterOnGoing === "select") {
    //   delete filter.ongoing;
    // }
    // if (filterIsActive === true) {
    //   filter.is_active = "true";
    // } else if (filterIsActive === false) {
    //   filter.is_active = "false";
    // }
    // if (filterIsActive === "select") {
    //   delete filter.is_active;
    // }
     
    if(lightNovelFilter){
      let filter = lightNovelFilter;
      filter.sort_by= mySortBy;
    }
    getLightNovelList(filter);
  };
  const getLightNovelList = async (lightNovelFilter) => {
    setLightNovelLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await lightNovelList(lightNovelFilter);
      if (result?.isSuccess) {
        setLightNovelLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setLightNovelLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setLightNovelLists([]);
    }
  };
  // const prevPage = async () => {
  //   setPage(page - 1);
  // };

  // const nextPage = async () => {
  //   setPage(page + 1);
  // };
  // const getUniverseList = async () => {
  //   const allUniverses = await get_universes_list();
  //   setUniverseList(allUniverses);
  // };

  const getPrePublicationList = async () => {
    const allPrePublication = await get_PrePublication_list();
    setPrePublicationList([{ id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',en_title:'None'},...allPrePublication]);
  };

  const getStatusList = async () => {
    const allStatus = await get_status_list();  
    setStatusList([{ id:'8f97245b-5d1a-4dfd-8158-7211baf22e82', name:'None'},...allStatus]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const jpPublisherList = allPublisher.filter((publisher) => publisher.publisher_type == 'JP')
    setPublisherList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...jpPublisherList]);
  };
  const getTypeList = async () => {
    const allType = await get_type_list();
    setTypeList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...allType]);
  };
  const getUserList = async () => {
    const allUser = await get_User_list();
    setUserList(allUser);
  };

  const commonLightNovelFilterOptions = (lightNovelFilterObject) => {

    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };

    const from_date = lightNovelFilterObject?.from_date;
    const to_date = lightNovelFilterObject?.to_date;
    const en_title = lightNovelFilterObject?.en_title;
    const jp_title = lightNovelFilterObject?.jp_title;
    const jp_raw = lightNovelFilterObject?.jp_raw;
    const pre_publication_id = lightNovelFilterObject?.pre_publication_id;
    const user_id =  lightNovelFilterObject?.user_id;
    const status_id = lightNovelFilterObject?.status_id;
    const publisher_id = lightNovelFilterObject?.publisher_id;
    const type_id = lightNovelFilterObject?.type_id;
    const is_bookmark = lightNovelFilterObject?.is_bookmark;

    if(en_title){
      filter.en_title = en_title.replace(/'/g, "''");
    }

    if (jp_title && jp_title.trim()) {
      filter.jp_title = jp_title.replace(/'/g, "''");
    }

    if (jp_raw && jp_raw.trim()) {
      filter.jp_raw = jp_raw.replace(/'/g, "''");
    }

    if(new Date(from_date) <= new Date(to_date)){
      filter.from_date = from_date;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter.to_date = to_date;
    }

    if(pre_publication_id){
      filter.pre_publication_id = pre_publication_id;
    }

    if(user_id){
      filter.user_id = user_id;
    }

    if(status_id){
      filter.status_id = status_id;
    }

    if(publisher_id){
      filter.publisher_id = publisher_id;
    }

    if(type_id){
      filter.type_id = type_id;
    }

    if (is_bookmark === true || is_bookmark === 'true') {
      filter.is_bookmark = true;
    } else if (is_bookmark === false || is_bookmark === 'false') {
      filter.is_bookmark = false;
    }
    if (is_bookmark === "select") {
      delete filter.is_bookmark;
    }

    // if (filterUniverse) {
    //   filter.universe_id = filterUniverse;
    // }
    
    // if (filterOnGoing === true) {
    //   filter.ongoing = "true";
    // } else if (filterOnGoing === false) {
    //   filter.ongoing = "false";
    // }
    // if (filterOnGoing === "select") {
    //   delete filter.ongoing;
    // }
    // if (filterIsActive === true) {
    //   filter.is_active = "true";
    // } else if (filterIsActive === false) {
    //   filter.is_active = "false";
    // }
    // if (filterIsActive === "select") {
    //   delete filter.is_active;
    // }

    return filter;

  }

  const searchData = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        en_title: filterENTitle,
        jp_title: filterJPTitle,
        jp_raw: filterJPRaw,
        pre_publication_id:filterPrePublication,
        user_id:filterUser,
        status_id:filterStatus,
        publisher_id:filterPublisher,
        type_id: filterType,
        is_bookmark :filterIsBookMark
      }
      
      const filter  = commonLightNovelFilterOptions(filterObject)

      setLightNovelFilter(filter)
      getLightNovelList(filter);
      setFirstTimeFilterActive(false)
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const removeSearch = () => {
    setFilterLoading(true);
    showLoader();
    setFilterENTitle("");
    setFilterJPTitle("");
    setFilterJPRaw("");
    setFilterPrePublication("");
    setFilterFromDate("");
    setFilterToDate("");
    // setFilterUniverse("");
    setFilterUser("");
    setFilterStatus("");
    setFilterPublisher("");
    setFilterType("");
    setFilterIsBookMark("");
    // setFilterIsActive("");
    // setFilterOnGoing("");
    // setUniverseSelected(null);
    setLightNovelFilter('')
    setUserSelected(null);
    setStatusSelected(null);
    setPublisherSelected(null);
    setTypeSelected(null);
    setPrePublicationSelected(null);
    setFirstTimeFilterActive(false)
    setPage(1)
    setIsRedirectFirstPage(1)
    // selectInputRefs.current.props.value.label = "select";
    selectInputRef.current.props.value.label = "select";
    const filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };
    navigate('/admin/lightNovel/light-Novel-list', { replace: true });
    getLightNovelList(filter);
    setFilterLoading(false);
    hideLoader();
  };

  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateLightNovelStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };

  const handleFilterWithPagination = async() => {
    const filter = commonLightNovelFilterOptions(lightNovelFilter);

    const lightNovelFilterValue = filter.from_date || filter.to_date || filter.jp_raw || filter.en_title || filter.pre_publication_id || filter.user_id ||  filter.status_id || filter.publisher_id || filter.type_id || filter.is_bookmark || filter.is_bookmark == 'false';

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (lightNovelFilterValue)){
          setPage(1)
          getLightNovelList(filter);
          setFirstTimeFilterActive(true);
          return;
      } 
  
      if(firstTimeFilterActive && totalPages <= filter.per_page && (lightNovelFilterValue)){
        setPage(1)
        getLightNovelList(filter);
        return;
      }
    }

    getLightNovelList(filter);       
      
  }

  useEffect(() => {
    // getUniverseList();
    getPrePublicationList();
    getStatusList();
    getPublisherList();
    getTypeList();
    getUserList();
    // eslint-disable-next-line
  }, [page, per_Page, sort_key, sort_by]);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

  const fetchUniverseDetail = async (unvierseId) => {
    if (unvierseId && unvierseId !== null) {
      setOpenUniverseModel(true);
      const res = await getUniverseSummaryById(unvierseId);

      if (res && res.isSuccess) {
        setUniverseSummaryData(res.data);
      }
    }
  };

  const fetchAuthorsDetail = async (authorId) => {
    if (authorId && authorId !== null) {
      setOpenAuthorModel(true);
      const res = await getAuthorSummaryById(authorId);
      if (res && res.isSuccess) {
        setAuthorSummaryData(res.data);
      }
    }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
    if (illustratorId && illustratorId !== null) {
      setOpenIllustratorModel(true);
      const res = await getIllustratorSummaryById(illustratorId);
      if (res && res.isSuccess) {
        setIllustratorSummaryData(res.data);
      }
    }
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("light-novel",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
  }

const handleLightNovelFilterSet = useCallback(()=> {

  if(enTitle|| jpTitle || jpRaw || fromDate|| toDate || filterPrePublication || filterStatus || filterPublisher || filterType || filterUser || filterIsBookMark){
    setIsRedirectFirstPage(0)
    if(filterPrePublication && prePublicationList.length > 0){
      const selectedPrePublication = prePublicationList.filter((data)=> filterPrePublication.includes(data.id));
      const finalSelectedPrePublicationResponse = selectedPrePublication.map((x)=>{
        return {
          value: x.id,
          label: x.en_title,
        };

      })

      setPrePublicationSelected(finalSelectedPrePublicationResponse)
    }
    if(filterUser && userList.length > 0){
      const selectedUser = userList.filter((data)=> filterUser.includes(data.id));
      const finalSelectedUserResponse = selectedUser.map((x)=>{
        return {
          value: x.id,
          label: `${x.first_name} ${x.last_name}`,
        };
      })

      setUserSelected(finalSelectedUserResponse)
    }

    if(filterStatus && statusList.length > 0){
      const selectedStatus = statusList.filter((data)=> filterStatus.includes(data.id));
      const finalSelectedStatusResponse = selectedStatus.map((x)=>{
        return {
          value: x.id,
          label: x.name,
        };
      })
      setStatusSelected(finalSelectedStatusResponse)
    }

    if(filterPublisher && publisherList.length > 0){
      const selectedPublisher = publisherList.filter((data)=> filterPublisher.includes(data.id));
      const finalSelectedPublisherResponse = selectedPublisher.map((x)=>{
        return {
          value: x.id,
          label: x.name,
        };
      })

      setPublisherSelected(finalSelectedPublisherResponse)
    }

    if(filterType && typeList.length > 0){
      const selectedType = typeList.filter((data)=> filterType.includes(data.id));
      const finalSelectedTypeResponse = selectedType.map((x)=>{
        return {
          value: x.id,
          label: x.name,
        };
      })

      setTypeSelected(finalSelectedTypeResponse)
    }
    callAPI()
  }
},[enTitle,filterFromDate,filterToDate,filterPrePublication,filterType,filterStatus,filterPublisher,filterUser,filterIsBookMark])

useEffect(()=>{
  handleLightNovelFilterSet()
},[enTitle,fromDate,toDate,prePublicationIdsArray,typeIdsArray,publisherIdsArray,userIdsArray,statusIdsArray,is_bookmark])


const getUrl = (baseUrl,view_light_novel_id=false) => {

  if(page){
    baseUrl += `?page=${page}`
  }

  if(filterENTitle){
    baseUrl+=`&en_title=${filterENTitle}`
  }

  if (filterJPTitle) {
    baseUrl += `&jp_title=${filterJPTitle}`;
  }

  if (filterJPRaw) {
    baseUrl += `&jp_raw=${filterJPRaw}`;
  }

  if(filterFromDate){
    baseUrl+=`&from_date=${filterFromDate}`
  }

  if(filterToDate){
    baseUrl+=`&to_date=${filterToDate}`
  }
  
  if(filterPrePublication && filterPrePublication.length >0 ){
    const prePublicationIds = filterPrePublication.join(',');
    baseUrl +=`&pre_publication_id=${prePublicationIds}`
  }

  if(filterUser && filterUser.length >0){
    const userIds = filterUser.join(',');
    baseUrl+= `&user_id=${userIds}`
  }

  if(filterPublisher && filterPublisher.length >0) {
    const publisherIds = filterPublisher.join(',');
    baseUrl += `&publisher_id=${publisherIds}`
  }

  if(filterType && filterType.length >0){
    const typeIds = filterType.join(',');
    baseUrl += `&type_id=${typeIds}`
  }

  if(filterStatus && filterStatus.length >0){
    const statusIds = filterStatus.join(',');
    baseUrl += `&status_id=${statusIds}`
  }

  if(filterIsBookMark || filterIsBookMark === false || filterIsBookMark === 'false' || filterIsBookMark === 'none'){
    baseUrl +=`&is_bookmark=${filterIsBookMark}`
  }

  if(view_light_novel_id){
    baseUrl += `&id=${view_light_novel_id}`
  }

  return baseUrl;
}

const updateLightNovelFlagStatus = async (lightNovelId) => {
  const filter = {
    page: page,
    per_page: per_Page,
    sort_key: sort_key,
    sort_by: sort_by,
  };

  await lightNovelUpdateFlagStatus({
    light_novel_id: lightNovelId,
  });

  getLightNovelList(filter);
};
const handleCopyLink = async (lightNovelId) => {
  const baseURL = `/admin/lightNovel/view-Light-Novel/${lightNovelId}`;
  const url = getUrl(baseURL,lightNovelId);
  const urlToCopy =  window.location.origin + url;
  try {
    await navigator.clipboard.writeText(urlToCopy);
    toast.success("Link copied successfully");
  } catch (error) {
    toast.error('Failed to copy the link.');
  }
};

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Light Novel List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-light-novel" ? (
                          <>
                            <Button
                              color="primary"
                              onClick={() => {
                                navigate("/admin/lightNovel/createLight-Novel");
                              }}>
                              {isLoading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}>
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!isLoading && (
                                <span className="indicator-label">
                                  Add Light Novel
                                </span>
                              )}
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => {
                                navigate("/admin/lightNovel/import-light-novel");
                              }}>
                              {isLoading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}>
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!isLoading && (
                                <span className="indicator-label">
                                  Import Light Novel
                                </span>
                              )}
                            </Button>
                          </>
                        ) : (
                          ""
                        )
                      )}
                    <Button onClick={toggle} className="filter_btn">
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen} {...args}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterFromDate)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              // ref={resetTo}
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterToDate)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              EN Title
                            </label>
                            <Input
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={filterENTitle}
                              onChange={(event) => {
                                setFilterENTitle(event.target.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterENTitle)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Title
                            </label>
                            <Input
                              id="jp_title"
                              name="jp_title"
                              placeholder="JP Title"
                              type="text"
                              value={filterJPTitle}
                              onChange={(event) => {
                                setFilterJPTitle(event.target.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterJPTitle)}
                            />
                          </FormGroup>
                        </Col>
                       {/* <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Universe
                            </label>
                            <Select
                              name="universe_id"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              id="universe_id"
                              className="react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={universeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUniverseSelected(selected);
                                setFilterUniverse(value);
                              }}
                            />
                          </FormGroup>
                        </Col>*/} 
                      </Row>
                      <Row>
                      <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Raw
                            </label>
                            <Input
                              className="form-control"
                              id="jp_raw"
                              name="jp_raw"
                              placeholder="JP Raw"
                              type="text"
                              value={filterJPRaw}
                              onChange={(event) => {
                                setFilterJPRaw(event.target.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterJPRaw)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Pre Publication
                            </label>
                            <Select
                              name="pre_publication_id"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              id="pre_publication_id"
                              className="react-select"
                              options={Array.from(prePublicationList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.en_title,
                                };
                              })}
                              value={prePublicationSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setPrePublicationSelected(selected);
                                setFilterPrePublication(value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterPrePublication)}
                            />
                          </FormGroup>
                          </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">User</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="universe_id"
                              id="universe_id"
                              className="react-select"
                              options={Array.from(userList).map((value) => {
                                return {
                                  value: value.id,
                                  label: `${value.first_name} ${value.last_name}`,
                                };
                              })}
                              value={userSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUserSelected(selected);
                                setFilterUser(value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterUser)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Status</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="status_id"
                              id="status_id"
                              className="react-select"
                              options={Array.from(statusList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={statusSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setStatusSelected(selected);
                                setFilterStatus(value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterStatus)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Publisher
                            </label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="publisher_id"
                              id="publisher_id"
                              className="react-select"
                              options={Array.from(publisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              value={publisherSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setPublisherSelected(selected);
                                setFilterPublisher(value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterPublisher)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={typeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setTypeSelected(selected);
                                setFilterType(value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterType)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is BookMark
                            </label>
                            <Select
                              name="is_bookmark"
                              id="is_bookmark"
                              className="react-select"
                              defaultValue={{
                                label: filterIsBookMark == 'true' ? "Yes" : filterIsBookMark == 'false'  ? "No" : filterIsBookMark == 'none'?'None':'Select',
                              }}
                              options={Array.from([{id:"none",name:'None'},...isBookMark]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsBookMark(event.value);
                              }}
                              onKeyDown = {(e) => handleFilterOnKeyEnter(e,searchData,filterIsBookMark)}
                              ref={selectInputRef}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/*<Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Ongoing
                            </label>
                            <Select
                              ref={selectInputRefs}
                              name="ongoing"
                              id="ongoing"
                              className="react-select"
                              defaultValue={{
                                label: "Select",
                              }}
                              options={Array.from(onGoing).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(e) => {
                                setFilterOnGoing(e.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              ref={selectInputRef}
                              defaultValue={{
                                label: "Select",
                                value: "select",
                              }}
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(e) => {
                                setFilterIsActive(e.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>*/}
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={searchData}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={removeSearch}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th
                              style={{ cursor: "pointer" }}
                              className="d-sm-none"
                              onClick={() =>
                                sortColumns("status_name", sort_by)
                              }>
                              Status
                              {sort_key === "status_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th title="Book Mark">BM</th>
                            <th title="Flag Status">FS</th>
                            <th>No.</th>
                            <th
                              className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("en_title", sort_by)}>
                              EN Title
                              {sort_key === "en_title" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th>Cover</th>

                            <th
                              style={{ cursor: "pointer" }}
                              className="d-none d-md-table-cell"
                              onClick={() =>
                                sortColumns("status_name", sort_by)
                              }>
                              Status
                              {sort_key === "status_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>

                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} 
                                alt="rating-1" 
                                style={{ width: '20px' }} 
                              />
                            </th>
                            
                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} 
                                alt="rating-1" 
                                style={{ width: '20px' }} 
                              />
                            </th>

                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} 
                                alt="rating-1" 
                                style={{ width: '18px' }} 
                              />
                            </th>

                            <th
                              className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("universe_name", sort_by)
                              }>
                              Universe
                              {sort_key === "universe_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                           
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("publisher_name", sort_by)
                              }>
                              JP publisher
                              {sort_key === "publisher_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("jp_title", sort_by)}>
                              JP Title
                              {sort_key === "jp_title" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("pre_publication_name", sort_by)
                              }>
                              Pre Publication
                              {sort_key === "pre_publication_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("no_of_volumes", sort_by)
                              }>
                              No. of vol
                              {sort_key === "no_of_volumes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("ongoing", sort_by)}>
                              Ongoing
                              {sort_key === "ongoing" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("type_name", sort_by)}>
                              Type
                              {sort_key === "type_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("print_rights_in_france", sort_by)
                              }>
                              Print Rights In France
                              {sort_key === "print_rights_in_france" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("digital_rights_in_france", sort_by)
                              }>
                              Digital Rights In France
                              {sort_key === "digital_rights_in_france" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("print_rights_in_us", sort_by)
                              }>
                              Print Rights In US
                              {sort_key === "print_rights_in_us" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("digital_rights_in_us", sort_by)
                              }>
                              Digital Rights In US
                              {sort_key === "digital_rights_in_us" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("print_rights_in_de", sort_by)
                              }>
                              Print Rights In DE
                              {sort_key === "print_rights_in_de" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("digital_rights_in_de", sort_by)
                              }>
                              Digital Rights In DE
                              {sort_key === "digital_rights_in_de" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_print_run_1", sort_by)
                              }>
                              JP Print Run 1
                              {sort_key === "jp_print_run_1" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_print_run_2", sort_by)
                              }>
                              JP Print Run 2
                              {sort_key === "jp_print_run_2" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_print_run_3", sort_by)
                              }>
                              JP Print Run 3
                              {sort_key === "jp_print_run_3" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_print_run_4", sort_by)
                              }>
                              JP Print Run 4
                              {sort_key === "jp_print_run_4" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-light-novel-product-pl" ? (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      sortColumns("product_pl", sort_by)
                                    }
                                  >
                                    Product P&L
                                    {sort_key === "product_pl" &&
                                      (sort_by === "ASC" ? (
                                        <i className="fa fa-arrow-up ml-1"></i>
                                      ) : (
                                        <i className="fa fa-arrow-down ml-1"></i>
                                      ))}
                                  </th>
                                ) : (
                                  ""
                                )
                              )}

                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_wikipedia_link", sort_by)
                              }>
                              JP Wikipedia
                              {sort_key === "jp_wikipedia_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("nautiljon", sort_by)
                              }>
                              Nautiljon
                              {sort_key === "nautiljon" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_amazon_link", sort_by)
                              }>
                              JP Amazon
                              {sort_key === "jp_amazon_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_bookwalker_link", sort_by)
                              }>
                              JP BookWalker
                              {sort_key === "jp_bookwalker_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("jp_raw", sort_by)}>
                              JP Raw
                              {sort_key === "jp_raw" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th>ScanTrad</th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-light-novel-low-sale" ? (
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("low_sales", sort_by)
                                      }>
                                      Low Sales
                                      {sort_key === "low_sales" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}
                                    </th>
                                ) : (
                                  ""
                                )
                              )}

                              {permissions &&
                                permissions.map((permission) =>
                                  permission === "view-light-novel-medium-sale" ? (
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("medium_sales", sort_by)
                                      }>
                                      Medium Sales
                                      {sort_key === "medium_sales" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}
                                    </th>
                                  ) : (
                                    ""
                                  )
                               )}

                              {permissions &&
                                permissions.map((permission) =>
                                  permission === "view-light-novel-high-sale" ? (
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("high_sales", sort_by)
                                      }>
                                      High Sales
                                      {sort_key === "high_sales" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}
                                    </th>   
                                  ) : (
                                    ""
                                  )
                              )}                
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns(
                                  "fr_consumer_price",
                                  sort_by
                                )
                              }>
                                FR Consumer Price
                                {sort_key === "fr_consumer_price" &&
                                  (sort_by === "ASC" ? (
                                    <i className="fa fa-arrow-up ml-1"></i>
                                  ) : (
                                    <i className="fa fa-arrow-down ml-1"></i>
                                  ))}
                            </th>  
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("authors", sort_by)}>
                              Authors
                              {sort_key === "authors" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("illustrators", sort_by)
                              }>
                              Illustrator
                              {sort_key === "illustrators" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("genres", sort_by)}>
                              Genre
                              {sort_key === "genres" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("publics", sort_by)}>
                              Public
                              {sort_key === "publics" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("summary", sort_by)}>
                              Summary
                              {sort_key === "summary" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-light-novel" ? (
                                  <>
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("is_active", sort_by)
                                      }>
                                      Is Active
                                      {sort_key === "is_active" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}{" "}
                                    </th>{" "}
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {lightNovelLists.length > 0 ? (
                            lightNovelLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              const randomIndexNumber = randomIndex();  
                              return (
                                <tr
                                  key={info.id}
                                  id={`light-novel-list-record-${info.id}`}
                                  style={{
                                    backgroundColor: info.flag_to_me
                                      ? colors[randomIndexNumber]
                                      : "",
                                  }}>
                                  <td className="d-sm-none">{info.status_name}</td>  
                                  <td>
                                  <Checkbox
                                      {...label}
                                      icon={<BookmarkBorderIcon />}
                                      checkedIcon={<BookmarkIcon />}
                                      defaultChecked={info.bookmark_id !== null ? true : false}
                                      onClick={(e)=>handleLightNovelBookMark({isChecked:e.target.checked,lightNovelId:info.id})}
                                  />
                                  </td>
                                  <td>
                                    {info?.flag_to_me ? (
                                      <Switch size="small" defaultChecked={info.flag_to_me ? true : false} onClick={()=>updateLightNovelFlagStatus(info.id)} disabled = {info.flag_to_me ? false : true}/>                                                          
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {indexNo}
                                  </td>

                                  <td
                                    className="word_wrap"
                                    style={{ cursor: "pointer" }}>
                                    {info.flag_to_me ? (
                                      <>
                                        <i
                                          className={
                                            "mr-2 fa fa-info-circle tooltip" +
                                            info.id
                                          }
                                          aria-hidden="true"></i>

                                        <Tooltip
                                          anchorSelect={".tooltip" + info.id}>
                                          {info.flag_to_me?.map((flag) => (
                                            <li>
                                              {flag.first_name} {flag.last_name}
                                            </li>
                                          ))}
                                        </Tooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <Link
                                      className="primary-text-color"
                                      onClick={async () => {
                                        const res = await getLightNovelById({
                                          lightNovel_id: info.id,
                                        });
                                        let baseURL = `/admin/lightNovel/view-Light-Novel/${info.id}`;
                                        const url = getUrl(baseURL);

                                        navigate(
                                          url,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                    <i className="fa fa-copy ml-2"  onClick={()=> handleCopyLink(info.id)}></i>
                                  </td>
                                  <td>
                                    {info.cover_image ? (
                                      <>
                                        <img
                                          className="cover_image"
                                          src={info.cover_image}
                                          onClick={async () => {
                                            setShowModal(true);
                                            setImage(info.cover_image);
                                          }}
                                          ref={imgRef}
                                          alt="book-cover"
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="d-none d-md-table-cell">{info.status_name}</td>
                                  <td 
                                    className={`text-center ${info.rating_one_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_one_counts !== "0"){
                                        setRatingType(1);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_one_counts}
                                  </td>
                                  <td 
                                    className={`text-center ${info.rating_two_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_two_counts !== "0"){
                                        setRatingType(2);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_two_counts}
                                  </td>
                                  <td 
                                    className={`text-center ${info.rating_three_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_three_counts !== "0"){
                                        setRatingType(3);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_three_counts}
                                  </td>
                                  <td
                                    className="word_wrap primary-text-color"
                                    onClick={() =>
                                      fetchUniverseDetail(info.universe_id)
                                    }
                                    style={{
                                      cursor: `${
                                        info.universe_id !== null
                                          ? "pointer"
                                          : "auto"
                                      }`,
                                    }}>
                                    {info.universe_name}
                                  </td>
                                  <td>{info.publisher_name}</td>
                                  <td className="word_wrap">
                                    {" "}
                                    {info.jp_title}
                                  </td>
                                  <td>{info.pre_publication_name}</td>
                                  <td>{info.no_of_volumes}</td>
                                  <td>
                                    {info.ongoing === true
                                      ? "Yes"
                                      : info.ongoing === false
                                      ? "No"
                                      : ""}
                                  </td>
                                  <td>{info.type_name}</td>
                                  <td>{info.print_rights_in_france_publisher_name}</td>
                                  <td>{info.digital_rights_in_france_publisher_name}</td>
                                  <td>{info.print_rights_in_us_publisher_name}</td>
                                  <td>{info.digital_rights_in_us_publisher_name}</td>
                                  <td>{info.print_rights_in_de_publisher_name}</td>
                                  <td>{info.digital_rights_in_de_publisher_name}</td>
                                  <td>{info.jp_print_run_1}</td>
                                  <td>{info.jp_print_run_2}</td>
                                  <td>{info.jp_print_run_3}</td>
                                  <td>{info.jp_print_run_4}</td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "view-light-novel-product-pl" ? (
                                        <td className="link primary-text-color">
                                          <a
                                            href={info.product_pl}
                                            target="_blank"
                                            rel="noreferrer">
                                            {info.product_pl}
                                          </a>
                                        </td>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  <td className="link primary-text-color">
                                    <a
                                      href={info.jp_wikipedia_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info.jp_wikipedia_link}
                                    </a>
                                  </td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info?.nautiljon}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info?.nautiljon}
                                    </a>
                                  </td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info.jp_amazon_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info.jp_amazon_link}
                                    </a>
                                  </td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info.jp_bookwalker_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info.jp_bookwalker_link}
                                    </a>
                                  </td>
                                  <td className="word_wrap">{info.jp_raw}</td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info.scantrad}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info.scantrad}
                                    </a>
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission ===
                                      "view-light-novel-low-sale" ? (
                                        <td>{info.low_sales}</td>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission ===
                                      "view-light-novel-medium-sale" ? (
                                          <td>{info.medium_sales}</td>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission ===
                                      "view-light-novel-high-sale" ? (
                                          <td>{info.high_sales}</td>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  <td>{info.fr_consumer_price}</td>
                                  <td
                                    className="primary-text-color" style={{cursor: "pointer"}}>
                                    {info.authors?.map((author, index) => (
                                      <span
                                        key={index}
                                        onClick={() =>
                                          fetchAuthorsDetail(author.author_id)
                                        }>
                                        {author.name}
                                        {index !== info.authors.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td
                                    className="primary-text-color" style={{cursor: "pointer"}}>
                                    {info.illustrators?.map(
                                      (illustrator, index) => (
                                        <span
                                          key={index}
                                          onClick={() =>
                                            fetchIllustratorDetail(
                                              illustrator.illustrator_id
                                            )
                                          }>
                                          {illustrator.name}
                                          {index !==
                                            info.illustrators.length - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {info.genres?.map((genre, index) => (
                                      <span key={index}>
                                        {genre.name}
                                        {index !== info.genres.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td>
                                    {info.publics?.map((publicsD, index) => (
                                      <span key={index}>
                                        {publicsD.name}
                                        {index !== info.publics.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td className="word_wrap">{info?.summary?.length > 50 ? `${info.summary.slice(0, 40)}...` : info.summary}</td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-light-novel" ? (
                                        <>
                                          <td>
                                            <label className="custom-toggle">
                                              <input
                                                onChange={() => {}}
                                                onClick={() => {
                                                  handleStatus({
                                                    id: info.id,
                                                    is_active: !info.is_active,
                                                  });
                                                }}
                                                value={info.is_active === true}
                                                defaultChecked={
                                                  info.is_active === true
                                                }
                                                type="checkbox"
                                              />
                                              <span className="custom-toggle-slider  rounded-circle" />
                                            </label>
                                          </td>
                                          <td
                                            className="action"
                                            style={{
                                              backgroundColor: info.flag_to_me
                                                ? colors[randomIndexNumber]
                                                : "",
                                            }}>
                                            <Button
                                              color="primary"
                                              onClick={async () => {
                                                const res =
                                                  await getLightNovelById({
                                                    lightNovel_id: info.id,
                                                  });

                                                let baseURL = `/admin/lightNovel/updateLightNovel/${info.id}`;
                                                const url = getUrl(baseURL);                                                  
                                                navigate(
                                                  url,
                                                  { state: { row: res?.data } }
                                                );
                                              }}>
                                              {isLoading && (
                                                <span
                                                  className="indicator-progress"
                                                  style={{ display: "block" }}>
                                                  Please Wait..
                                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                </span>
                                              )}
                                              {!isLoading && (
                                                <span className="indicator-label">
                                                  Edit
                                                </span>
                                              )}
                                            </Button>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink
                    className={"p-0 " + (page !== 1 ? "active-button" : "")}
                    disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      disabled={page === 1}
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink
                    className={
                      "p-0 " + (page !== totalPages ? "active-button" : "")
                    }
                    disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      disabled={page === totalPages}
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
        <ImageModal
          show={show}
          image={image}
          onClose={() => {
            setShowModal(false);
          }}
        />
        <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData}/>
        <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData }/>
        <IllustratorModal openIllustratorModel = {openIllustratorModel} setOpenIllustratorModel={setOpenIllustratorModel} illustratorSummaryData={illustratorSummaryData} />
        <RatingUserListModal openUserRatingListModel={openUserRatingListModel} setOpenUserRatingListModel={setOpenUserRatingListModel} ratingInfo={ratingInfo} ratingType = {ratingType} title = "Light Novel Rating Details" />
      </Container>
      {loader}
    </>
  );
}
export default LightNovelList;
