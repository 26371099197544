import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Collapse,
  CardHeader,
  Col,
  Input,
  Container,
  FormGroup,
  Row,
  Modal,
} from "reactstrap";
import Select from "react-select";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";
import {
  universeList,
  getUniverseSummaryById,
  updateUniverseStatus,
} from "utils/endpoint";
import { originalMedium } from "utils/enums";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import { getUniverseById } from "utils/endpoint";
import { Pagination as Paginationes } from "antd";
import { deleteUniverse } from "utils/endpoint";
import { getUniverses } from "utils/endpoint";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
import { handleFilterOnKeyEnter } from "views/media/lightNovel/LightNovelList";

export const parseURLParams = (url) => {
  let params = {};
  let urlParts = url.split(/[?&]/);
  urlParts.shift(); // remove first element which is empty

  urlParts.forEach(function (param) {
      let keyValue = param.split("=");
      params[keyValue[0]] = decodeURIComponent(keyValue[1]);
  });
  return params;
}

function UniverseList() {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const name = params.name;
  const original_medium = params.original_medium;
  const noOfMediaCount = params.noOfMediaCount;
  const is_active = params.is_active;

  const [isLoading, setIsLoading] = useState(false);
  const [universeLists, setUniverseLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterName, setFilterName] = useState(name || "");
  const [filterOriginalMedium, setFilterOriginalMedium] = useState(original_medium || "");
  const [filterIsActive, setFilterIsActive] = useState(is_active || "");
  const [filterTotalNumberOfMediaCounts,setFilterTotalNumberOfMediaCounts] = useState(noOfMediaCount || '')
  const [permissions, setPermissions] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const selectActiveInputRef = useRef(null);
  const selectOriginalMediumInputRef = useRef(null);
  const [universeId, setUniverseId] = useState(null);
  const [universeFilter,setUniverseFilter] = useState('')
  const [show, setShowModal] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");
  
  const deleteUniverseModal = async () => {
    setIsLoading(true);
    showLoader();
    try {
      const result = await deleteUniverse(universeId)
      if (result?.isSuccess) {
        toast.success(`${result.message}`);
        setIsLoading(false);
        hideLoader();
        const res = await getUniverses();
        setUniverseLists(res?.data);
        setShowModal()
      } else {
        setIsLoading(false);
        hideLoader();
        setShowModal()
         return toast.error(
          `${result.message}`
        );
      }
    }
    catch (err){
          toast.error("Can Not Delete Universe" + err);
        }
  }
  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${mySortKey}&sort_by=${mySortBy}`;

    // if (new Date(filterFromDate) <= new Date(filterToDate)) {
    //   filter += `&from_date=${filterFromDate}`;
    // }

    // if (new Date(filterToDate) >= new Date(filterFromDate)) {
    //   filter += `&to_date=${filterToDate}`;
    // }

    // if (filterName && filterName.trim()) {
    //   filter += `&name=${filterName}`;
    // }

    // if (filterOriginalMedium) {
    //   filter += `&original_medium=${filterOriginalMedium}`;
    // }

    // if (filterIsActive) {
    //   filter += `&is_active=${filterIsActive}`;
    // } else {
    //   filter += `&is_active=${filterIsActive}`;
    // }
    if(universeFilter){
      let filter = universeFilter;
      filter.sort_by= mySortBy;
    }
    getUniverseList(filter);
  };
  const getUniverseList = async (universeFilter) => {
    setUniverseLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await universeList(universeFilter);
      if (result?.isSuccess) {
        setUniverseLists(result?.data?.data?.records);
        setTotalPages(result?.data?.data?.totalRecords);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setUniverseLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setUniverseLists([]);
    }
  };

  // const prevPage = async () => {
  //   setPage(page - 1);
  // };

  // const nextPage = async () => {
  //   setPage(page + 1);
  // };

  // const fetchUniverseList = async (filter) => {
  //   if (
  //     filterFromDate ||
  //     filterToDate ||
  //     filterName ||
  //     filterOriginalMedium ||
  //     filterTotalNumberOfMediaCounts ||
  //     filterIsActive ||
  //     filterIsActive === false
  //   ) {
  //     getUniverseList(filter);
  //   }
  // };

  const commonUniverseFilterOptions = (universeFilterObject) => {

    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
  
    const from_date = universeFilterObject?.from_date;
    const to_date = universeFilterObject?.to_date;
    const name = universeFilterObject?.name;
    const original_medium =  universeFilterObject?.original_medium;
    const total_no_of_media = universeFilterObject?.total_no_of_media;
    const is_active = universeFilterObject?.is_active;

    if(new Date(from_date) <= new Date(to_date)){
      filter += `&from_date=${filterFromDate}`;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter += `&to_date=${filterToDate}`;
    }

    if (name && name.trim()) {
      filter += `&name=${name.replace(/'/g, "''")}`;
    }

    if (original_medium) {
      filter += `&original_medium=${original_medium}`;
    }

    if (total_no_of_media) {
      filter += `&total_no_of_media=${total_no_of_media}`;
    }
    
    if (is_active) {
      filter += `&is_active=${is_active}`;
    } else {
      filter += `&is_active=${is_active}`;
    }
     
   return filter;
  }

  const handleUniverseListAddFilter = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        name: filterName,
        total_no_of_media:filterTotalNumberOfMediaCounts,
        original_medium: filterOriginalMedium,
        is_active : filterIsActive
      }

      const filter  = commonUniverseFilterOptions(filterObject)
      const reponse = parseURLParams(filter);
      setUniverseFilter(reponse);
      getUniverseList(filter);
      setFirstTimeFilterActive(false)
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const handleUniverseListCancelFilter = () => {
    setFilterLoading(true);
    showLoader();
    setFilterName("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterOriginalMedium("");
    setUniverseFilter('')
    setFilterIsActive("");
    setFilterTotalNumberOfMediaCounts("")
    setIsRedirectFirstPage(1)
    selectActiveInputRef.current.props.value.label = "";
    selectActiveInputRef.current.props.value.label = "select";
    selectOriginalMediumInputRef.current.props.value.label = "";
    selectOriginalMediumInputRef.current.props.value.label = "select";
    setFirstTimeFilterActive(false)
    setPage(1)
    const filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
    navigate('/admin/universe/universe-list', { replace: true });
    getUniverseList(filter);
    setFilterLoading(false);
    hideLoader();
  };

 
  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateUniverseStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };


  if(id !== null){
    const response = backToPreviousClickedRecord("universe",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
  }

  const handleFilterWithPagination = async() => {

    const response = commonUniverseFilterOptions(universeFilter);

    const filter = parseURLParams(response);

    const universeFilterValue = filter.from_date || filter.to_date || filter.name || filter.total_no_of_media ||  filter.original_medium || filter.is_active || filter.is_active === 'false' ;

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (universeFilterValue !== 'undefined')){
          setPage(1)
          getUniverseList(response);
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (universeFilterValue)){
        setPage(1)
        getUniverseList(response);
        return;
      }
    } 
    getUniverseList(response);
      
  }

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

 
  
  useEffect(()=>{
    if(filterName|| filterFromDate|| filterToDate|| filterIsActive || filterOriginalMedium || filterTotalNumberOfMediaCounts ){
      setIsRedirectFirstPage(0)
      if(original_medium){
        const originalMediumData = originalMedium.filter((x)=>x.name === original_medium)
        setFilterOriginalMedium(originalMediumData[0]?.id)
      }

      callAPI()
    }
  },[name,fromDate,toDate,noOfMediaCount,is_active,original_medium])

  const getUrl = (baseUrl) => {

    if(filterName){
      baseUrl+=`&name=${filterName}`
    }

    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }

    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterIsActive || filterIsActive === false || filterIsActive === 'none'){
      baseUrl +=`&is_active=${filterIsActive}`
    }

    if(filterOriginalMedium || filterOriginalMedium === 'none'){
      baseUrl+= `&original_medium=${filterOriginalMedium}`
    }

    if(filterTotalNumberOfMediaCounts) {
      baseUrl += `&no_of_media_count=${filterTotalNumberOfMediaCounts}`
    }
  
    return baseUrl;
  }


  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Universe List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-universe" ? (
                          <Button
                            color="primary"
                            onClick={() => {
                              navigate("/admin/universe/create-universe");
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">
                                Add Universe
                              </span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}

                    <Button onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterFromDate)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterToDate)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Name</label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={filterName}
                              onChange={(event) => {
                                setFilterName(event.target.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterName)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Original Medium
                            </label>
                            <Select
                              name="original_medium"
                              id="original_medium"
                              defaultValue={{
                                label: filterOriginalMedium !== 'none' && filterOriginalMedium !== '' || null ? filterOriginalMedium : filterOriginalMedium == 'none' ? 'None':'Select' ,
                              }}
                              options={Array.from([{id:'none',name:'None'},...originalMedium]).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFilterOriginalMedium(event.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterOriginalMedium)}
                              ref={selectOriginalMediumInputRef}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                        <FormGroup>
                            <label className="form-control-label">No Of Media Count</label>
                            <Input
                              id="total_no_of_media"
                              name="total_no_of_media"
                              placeholder="No Of Media Count"
                              type="text"
                              value={filterTotalNumberOfMediaCounts}
                              onChange={(event) => {
                                setFilterTotalNumberOfMediaCounts(event.target.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterTotalNumberOfMediaCounts)}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: filterIsActive === "true" ? "Yes" : filterIsActive === "false"  ? "No" : filterIsActive == 'none' ? 'None':'Select'
                              }}
                              options={Array.from([{id:'none',name:'None'},...isActive]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsActive(event.value);
                              }}
                              onKeyDown={(e) => handleFilterOnKeyEnter(e,handleUniverseListAddFilter,filterIsActive)}
                              ref={selectActiveInputRef}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleUniverseListAddFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleUniverseListCancelFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("name", sort_by)}>
                              {" "}
                              Name
                              {sort_key === "name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}{" "}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("original_medium", sort_by)
                              }>
                              Original Medium
                              {sort_key === "original_medium" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("total_no_of_media", sort_by)
                              }>
                              No of media count
                              {sort_key === "total_no_of_media" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_wikipedia_link", sort_by)
                              }>
                              Jp wikipedia Link
                              {sort_key === "jp_wikipedia_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}{" "}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("is_active", sort_by)}>
                              Is Active
                              {sort_key === "is_active" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}{" "}
                            </th>{" "}
                            <th className="action">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {universeLists.length > 0 ? (
                            universeLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id}  id={`universe-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td className="word_wrap primary-text-color" style={{ cursor: "pointer" }}>
                                    <Link
                                      className="indicator-label"
                                      onClick={async () => {
                                        const unvierseId = info.id;
                                        const res =
                                          await getUniverseSummaryById(
                                            unvierseId
                                          );
                                        let baseURL = `/admin/universe/view-universe/${unvierseId}?page=${page}`;
                                        const url = getUrl(baseURL);
                                        navigate(
                                          url,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.name}
                                    </Link>
                                  </td>
                                  <td>{info.original_medium}</td>
                                  <td>{info.total_no_of_media}</td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info.jp_wikipedia_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info.jp_wikipedia_link}
                                    </a>
                                  </td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    <label className="custom-toggle">
                                      <input
                                        onChange={() => {}}
                                        onClick={() => {
                                          handleStatus({
                                            id: info.id,
                                            is_active: !info.is_active,
                                          });
                                        }}
                                        value={info.is_active === true}
                                        defaultChecked={info.is_active === true}
                                        type="checkbox"
                                      />
                                      <span className="custom-toggle-slider  rounded-circle" />
                                    </label>
                                  </td>

                                  <td className="action">
                                    <Button
                                      color="primary"
                                      onClick={async () => {
                                        const res = await getUniverseById({
                                          universe_id: info.id,
                                        });
                                        let baseURL = `/admin/universe/update-universe/${info.id}?page=${page}`;
                                        const url = getUrl(baseURL);

                                        navigate(
                                          url,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {isLoading && (
                                        <span
                                          className="indicator-progress"
                                          style={{ display: "block" }}>
                                          Please Wait..
                                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                        </span>
                                      )}
                                      {!isLoading && (
                                        <span className="indicator-label">
                                          Edit
                                        </span>
                                      )}
                                    </Button>

                                    {permissions &&
                                      permissions.map((permission) =>
                                        permission === "delete-universe" ? (
                                          <Button
                                            color="danger"
                                            className="iconStyle"
                                            onClick={async () => {
                                              setUniverseId(info.id);
                                              setShowModal(true);
                                            }}>
                                            {" "}
                                            <span className="indicator-label">
                                              Delete
                                            </span>
                                          </Button>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
                <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (page !== 1 ? 'active-button' : '')} disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (page !== totalPages ? 'active-button' : '')} disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={show}
          toggle={() => {
            setShowModal(false);
          }}>
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent d-flex justify-content-between">
                <div className="form-control-label">Delete Universe</div>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}>
                  <span aria-hidden={true}>×</span>
                </button>
              </CardHeader>
              <CardBody>
                <div className="text-center">
                  <p className="form-control-label">
                    Are you sure you want to delete this universe?
                  </p>
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => {
                      deleteUniverseModal(universeId);
                    }}>
                    Delete
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
          {loader}
        </Modal>
       
      </Container>
      {loader}
    </>
  );
}

export default UniverseList;
